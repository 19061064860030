import React, { createContext, useMemo, useState } from 'react';
import { Header, Icon } from 'semantic-ui-react';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import { Styles } from '../../../res';
import AllowCopyPaste from '../../Operantinals/AllowCopyPaste';

const { f_16_700_text } = Styles;

export const FreeModalContext = createContext();

const FreeModal = ({ title, onClose, open, children, fileKey }) => {

    const top = useMemo(() => `${Math.ceil(Math.random() * 30) + 5}%`, []);
    const left = useMemo(() => `${Math.floor(Math.random() * 20) + 5}%`, []);
    const [width, setWidth] = useState(400);
    const [height, setHeight] = useState(500);
    const [finalWidth, setFinalWidth] = useState(width);

    const onResize = (event, { node, size, handle }) => {
        setWidth(size.width);
        setHeight(size.height);
    };

    const onStopResizing = (_, { size }) => {
        setFinalWidth(size.width);
    };

    if (!open || open === 0) return null;

    return (
        <AllowCopyPaste>
            <Draggable handle="#handle" cancel={"input, svg, button, table"} enableUserSelectHack={false}>
                <Resizable height={height} width={width} onResize={onResize} onResizeStop={onStopResizing}>

                    <div style={{ zIndex: open, border: 'solid 1px #ccc', position: 'absolute', top, left, backgroundColor: 'white' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: width + 'px', height: height + 'px', overflow: 'auto', position: 'relative' }}>
                            <div id="handle" style={{ padding: 5, zIndex: 10, cursor: 'move', position: 'sticky', top: 0, backgroundColor: '#dcdddd', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <Header style={f_16_700_text}>{title}</Header>
                                </div>

                                <div>
                                    <Icon
                                        link name='x'
                                        onClick={() => onClose(prev => {
                                            const temp = { ...prev };
                                            delete temp[fileKey];
                                            return { ...temp };
                                        })}
                                    />
                                </div>
                            </div>
                            <div style={{ borderTop: 'solid 1px #ccc' }} />
                            <div style={{ position: 'relative', flexGrow: 1 }}>
                                <div style={{ position: 'absolute', inset: 0, padding: 20 }}>
                                    <FreeModalContext.Provider value={finalWidth}>
                                        {children}
                                    </FreeModalContext.Provider>
                                </div>
                            </div>
                        </div>
                    </div>
                </Resizable>
            </Draggable>
        </AllowCopyPaste>
    );
};

export default FreeModal;